// const axios = require('axios').default;
//
// export default axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
//   timeout: 20000,
//   header: {
//     Authorization: 'bearer accessKey',
//   },
// });

export const staticSrc = 'https://www.sbdc.or.kr/assets/dongban';
