import { staticSrc } from '../src/api';
import styled from 'styled-components';

const NotFoundWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  width: 1100px;
`;
const NotFoundContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BigFont = styled.p`
  font-size: 30px;
  color: #3d3d3d;
  margin: 30px 0;
`;

const FooterFont = styled.p`
  font-size: 15px;
  color: #b8b8b8;
  margin-top: 30px;
`;

const NotFound = () => {
  return (
    <NotFoundWrapper>
      <NotFoundContentWrapper>
        <img src={`${staticSrc}/deny.png`} alt="" />
        <BigFont>페이지를 찾을 수 없습니다.</BigFont>
        <FooterFont>COPYRIGHT © 2023 SMALL & MEDIUM BUSINESS DISTRIBUTION CENTER. ALL RIGHTS RESERVED.</FooterFont>
      </NotFoundContentWrapper>
    </NotFoundWrapper>
  );
};

export default NotFound;
